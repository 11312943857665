import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './tests/reportWebVitals';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import App from "./App/App";
import {RouteName} from "./constants/RouteName";
import LegalNotice from "./LegalNotice/LegalNotice";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "./TermsAndConditions/TermsAndConditions";
import {initReactI18next} from "react-i18next";
import i18next from "i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslation from './locales/en-translation.json';
import deTranslation from './locales/de-translation.json';

i18next.use(LanguageDetector).use(initReactI18next).init({
    interpolation: {escapeValue: false},
    fallbackLng: 'en',
    resources: {
        en: {translation: enTranslation},
        de: {translation: deTranslation},
    },
})

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route index element={<App/>}/>
                <Route path={RouteName.legalNotice} element={<LegalNotice/>}/>
                <Route path={RouteName.privacyPolicy} element={<PrivacyPolicy/>}/>
                <Route path={RouteName.termsAndConditions} element={<TermsAndConditions/>}/>
                <Route path='*' element={<App/>}/>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
