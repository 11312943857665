import React from 'react';
import './LegalNotice.css';
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";

function LegalNotice() {
    const {t} = useTranslation();

    return (
        <div className='page' id='legal-notice'>
            <Header/>
            <h1>{t('legalNotice')}</h1>
            <div id='address'>
                <h3>{t('address')}</h3>
                <p>Severin Hanke</p>
                <p>c/o Block Services</p>
                <p>Stuttgarter Str. 106</p>
                <p>70736 Fellbach</p>
            </div>
            <div id='contact'>
                <h3>{t('contact')}</h3>
                <a href='mailto:mail@severin-hanke.de' title={t('email')}>
                    <div>
                        <FontAwesomeIcon icon={faEnvelope}/>
                        <p>mail@severin-hanke.de</p>
                    </div>
                </a>
            </div>
            <Footer/>
        </div>
    );
}

export default LegalNotice;
