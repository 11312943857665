import React from 'react';
import './Footer.css';
import {RouteName} from "../constants/RouteName";
import {useTranslation} from "react-i18next";

function Footer() {
    const {t} = useTranslation();

    return (
        <footer>
            <a href={RouteName.homePage}>{t('homepage')}</a>
            <a href={RouteName.legalNotice}>{t('legalNotice')}</a>
            <a href={RouteName.privacyPolicy}>{t('privacyPolicy')}</a>
            <a href={RouteName.termsAndConditions}>{t('termsAndConditions')}</a>
        </footer>
    );
}

export default Footer;
