import React from 'react';
import './App.css';
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import {useTranslation} from "react-i18next";

import AppStore from "../assets/Download_on_the_App_Store_Badge.png";
import PlayStore from "../assets/Get_it_on_Google_Play_Badge.png";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEnvelope} from "@fortawesome/free-solid-svg-icons";

function App() {
    const {t} = useTranslation();

    return (
        <div className='page' id='app'>
            <Header/>
            <p>{t('welcome')}</p>
            <p>{t('description')}</p>
            <div id='store-badges'>
                <a href='/app-store-link' title='App Store' target='_blank' rel='noopener noreferrer'>
                    <img src={AppStore} alt='App Store'/>
                </a>
                <a href='/play-store-link' title='Play Store' target='_blank' rel='noopener noreferrer'>
                    <img src={PlayStore} alt='Play Store'/>
                </a>
            </div>
            <div id='shop-owner'>
                <p>{t('shopOwner')}</p>
                <a href='mailto:mail@severin-hanke.de' title={t('email')}>
                    <div>
                        <FontAwesomeIcon icon={faEnvelope}/>
                        <p>mail@severin-hanke.de</p>
                    </div>
                </a>
            </div>
            <Footer/>
        </div>
    );
}

export default App;
