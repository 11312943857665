import React from 'react';
import './Header.css';
import {useTranslation} from "react-i18next";

import AppIcon from "../assets/app_icon_1024.png";

function Header() {
    const {t} = useTranslation();

    return (
        <header>
            <img src={AppIcon} alt={t('appIcon')}/>
            <h1>{t('appName')}</h1>
        </header>
    );
}

export default Header;
